<template>
  <div class="a-new-details">
    <div
      class="details-backgroun"
      :style="
        'background-image: linear-gradient(90deg, rgba(34,37,44,.6) 15%,rgba(19,21,77,.1) 80%),url(' +
        idoinfoObj.banner +
        ');border-radius: 30px;'
      "
    ></div>
    
    <div class="flexbox">
      <div class="schedule" v-loading="linksLoading">
        <div class="top">
          <div
            :class="['path cur', progress >= 1 ? 'path-path' : '']"
            @click="btn_whiteList(1)"
          >
            <svg-icon iconClass="success"></svg-icon>
            WhiteList
          </div>
          <i class="el-icon-arrow-right"></i>
          <div
            :class="['path cur', progress >= 2 || LotteryBOX ? 'path-path' : '']"
            @click="btn_whiteList(2)"
          >
            <svg-icon iconClass="success"></svg-icon>
            Lottery
          </div>
          <i class="el-icon-arrow-right"></i>
          <div
            :class="['path cur', progress >= 3 || IDOBox ? 'path-path' : '']"
            @click="btn_whiteList(3)"
          >
            <svg-icon iconClass="success"></svg-icon>
            IDO
          </div>
          <i class="el-icon-arrow-right"></i>
          <div
            :class="[
              'path cur',
              progress >= 4 || DistributionBox ? 'path-path' : '',
            ]"
            @click="btn_whiteList(4)"
          >
            <svg-icon iconClass="success"></svg-icon>
            Distribution
          </div>
        </div>
        <template v-if="TheWalletLinks">
          <div>
            <div class="contianer conter">
              <div
                class="WhiteList cur"
                v-if="IF_WhiteList && progress == 1"
                @click="detailPage(idoinfoObj.id)"
              >
                Apply for whitelist now
              </div>
              <div
                v-if="!IF_WhiteList && progress == 1"
                class="WhiteList"
                style="background: #3a3f45"
              >
                Whitelist is Closed
              </div>
              <!-- 未中奖 -->
              <div class="Lottery" v-if="IF_Lottery == 2 && progress == 2">
                Unfortunately, you were not selected in the public sale lottery
              </div>
              <!-- 到公示时间未导入中奖者 -->
              <div class="Lottery" v-if="IF_Lottery == 3 && progress == 2">
                The list will be announced soon,please wait
              </div>
              <div class="Lottery" v-if="IF_Lottery == 1 && progress == 2">
                You Won
                <span style="font-weight: bold; color: #1edfa6"
                  >{{ idoisWinnerList.quotaInput }}
                  {{ idoisWinnerList.inputToken }}</span
                >
                Allocation
                <div
                  class="Confirm-Participation"
                  v-if="PutTheAsh"
                  @click="Participation"
                >
                  Confirm Participation
                </div>
                <div
                  class="Confirm-Participation"
                  style="background: #96a4d1"
                  v-else
                >
                  Confirm Participation
                </div>
              </div>
              <div class="IDO Lottery" v-if="!IF_IDO && progress == 3">
                Unfortunately, you were not selected in the IDO
              </div>
              <div class="IDO Lottery" v-if="IF_IDO && progress == 3">
                You Won
                <span style="font-weight: bold; color: #1edfa6"
                  >{{ idoisWinnerList.inputAmount }}
                  {{ idoisWinnerList.inputToken }}</span
                >
                Allocation
                <div
                  class="Purchase"
                  v-if="PutTheAsh1"
                  @click="if_idodeal = true"
                >
                  Purchase
                </div>
                <div class="Purchase" style="background: #96a4d1" v-else>
                  Purchase
                </div>
              </div>

              <div
                class="Distribution Lottery"
                v-if="!IF_Distribution && progress == 4"
              >
                <div>
                  <p v-for="(item, index) in idoinfoObj.extract" :key="index">
                    Phase {{ index + 1 }} - {{ item.percent.toFixed(2) }}% (
                    {{ blockhieght + ' / ' +  item.height }} ) on {{ item.time }} - {{ item.condition }}
                  </p>
                </div>
              </div>
              <div
                class="Distribution Lottery"
                v-if="IF_Distribution && progress == 4"
              >
                <div>
                  <p v-for="(item, index) in idoinfoObj.extract" :key="index">
                    Phase {{ index + 1 }} - {{ item.percent.toFixed(2) }}% (
                    {{blockhieght + " / " + item.height }} ) on {{ item.time }} - {{ item.condition }}
                  </p>
                </div>
                <!-- 领取 -->
                <div class="Claim" @click="Claim()" v-if="Amount > 0">Claim</div>
              </div>
              <!-- 即将开始 -->
              <div
                v-if="EndBegins"
                class="conter"
                style="
                  height: 100px;
                  font-size: 16px;
                  font-weight: bold;
                  color: #ffffff;
                "
              >
                It's about to start, so stay tuned
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="not-login">
            <div class="not-login-btn" @click="purseOn">Connect Wallet</div>
          </div>
        </template>
      </div>
      
      <div class="blockght" v-if="boxheight">Current height: {{blockhieght}}</div>
    </div>

    <div class="cont">
      <!-- left -->
      <div class="left">
        <div class="essay" v-if="idoinfoObj.aboutLaunchpad">
          <!-- 关于打新 -->
          <p class="essay-title" id="aboutLaunchpad">About Launchpad</p>
          <div class="content" v-html="idoinfoObj.aboutLaunchpad"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.overview">
          <!-- 项目概况 -->
          <p class="essay-title" id="overview">PROJECT OVERVIEW</p>
          <div class="content" v-html="idoinfoObj.overview"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.marketStrategy">
          <!-- 2.  上市策略 -->
          <p class="essay-title" id="marketStrategy">GO-TO-MARKET STRATEGY</p>
          <div class="content" v-html="idoinfoObj.marketStrategy"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.productViability">
          <!-- 3. 产品可行性 -->
          <p class="essay-title" id="productViability">PRODUCT VIABILITY</p>
          <div class="content" v-html="idoinfoObj.productViability"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.productRoadmap">
          <!-- 4. 产品路线图 -->
          <p class="essay-title" id="productRoadmap">PRODUCT ROADMAP</p>
          <div class="content" v-html="idoinfoObj.productRoadmap"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.revenueStreams">
          <!-- 5.收入流 -->
          <p class="essay-title" id="revenueStreams">REVENUE STREAMS</p>
          <div class="content" v-html="idoinfoObj.revenueStreams"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.team">
          <!-- 6. 团队 -->
          <p class="essay-title" id="team">TEAM</p>
          <div class="content" v-html="idoinfoObj.team"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.tokenEconomy">
          <!-- 7. 代币制 -->
          <p class="essay-title" id="tokenEconomy">TOKEN ECONOMY</p>
          <div class="content" v-html="idoinfoObj.tokenEconomy"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.tokenMetricsDetailed">
          <!-- 8. 令牌指标详情 -->
          <p class="essay-title" id="tokenMetricsDetailed">
            DETAILED TOKEN METRICS
          </p>
          <div class="content" v-html="idoinfoObj.tokenMetricsDetailed"></div>
        </div>
        <div class="essay" v-if="idoinfoObj.partners">
          <!-- 9. 合作伙伴 -->
          <p class="essay-title" id="partners">PARTNERS</p>
          <div class="content" v-html="idoinfoObj.partners"></div>
        </div>
      </div>

      <!-- rigth -->
      <div class="right">
        <div class="BitHotel" style="width: 100%">
          <img class="logo-img" :src="infosimpleObj.logo" alt="" srcset="" />
          <p class="title-tel">{{ infosimpleObj.name }}</p>
          <p class="title-type">
            {{ infosimpleObj.outputToken }} / {{ infosimpleObj.inputToken }}
          </p>
          <p
            class="title-type"
            style="font-size: 14px; margin-top: 20px"
            v-if="preparingbox"
          >
            {{ $t("market") }}
          </p>
          <!-- Connect Wallet -->
          <div class="Connect-Wallet" v-if="ConnectWallet" @click="purseOn">
            Connect Wallet
          </div>
          <p
            style="
              height: 1px;
              background: #565b63;
              opacity: 0.24;
              margin: 20px 0;
            "
          ></p>
          <div class="sharing">
            <svg-icon
              iconClass="internet"
              @click="winopen(infosimpleObj.website)"
            ></svg-icon>
            <template v-for="itemlogo in idoinfoObj.community">
              <svg-icon
                :iconClass="itemlogo.name"
                :key="itemlogo.id"
                @click="winopen(itemlogo.url)"
              ></svg-icon>
            </template>
          </div>
          <p
            style="
              height: 1px;
              background: #565b63;
              opacity: 0.24;
              margin: 20px 0;
            "
          ></p>
          <div style="padding: 0 20px">
            <p class="exchange">
              1 {{ infosimpleObj.inputToken }} =
              {{ infosimpleObj.reverseInitialTokenPrice }}
              {{ infosimpleObj.outputToken }}
            </p>
            <p class="exchange">
              1 {{ infosimpleObj.outputToken }} =
              {{ infosimpleObj.initialTokenPrice }}
              {{ infosimpleObj.inputToken }}
            </p>
            <p v-if="countDown" style="font-size: 14px; margin-top: 20px">
              Registration opens in
              {{ $tool.succTime(infosimpleObj.whiteListStartTime) }}.
            </p>
            <el-progress
              :percentage="
                proportions(
                  infosimpleObj.fundraiseProgressInput,
                  infosimpleObj.fundraiseGoalInput
                )
              "
              color="#1EDFA6"
              :show-text="false"
            ></el-progress>
            <div class="proportion">
              <p style="color: #22dda7; font-size: 12px">
                {{
                  $tool.formatMonenyNumber(infosimpleObj.fundraiseProgressInput)
                }}
                {{ infosimpleObj.inputToken }}
              </p>
              <p style="font-size: 12px">
                {{ $tool.formatMonenyNumber(idoinfoObj.fundraiseProgress) }} /
                {{ $tool.formatMonenyNumber(infosimpleObj.fundraiseGoal) }}
                {{ infosimpleObj.outputToken }}
              </p>
            </div>
          </div>

          <p
            style="
              height: 1px;
              background: #565b63;
              opacity: 0.24;
              margin: 20px 0;
            "
          ></p>
          <div style="padding: 0 20px">
            <!-- <p class="frap">The Timeline</p> -->
            <p class="frap">WhiteList：</p>
            <p class="frap">
              {{ idoinfoObj.whiteListStartTime }} –
              {{ idoinfoObj.whiteListEndTime }}
            </p>
            <p class="frap">Lottery：</p>
            <p class="frap">{{ idoinfoObj.lotteryDrawTime }}</p>
            <p class="frap">IDO：</p>
            <p class="frap">
              {{ idoinfoObj.sellStartTime }} – {{ idoinfoObj.sellEndTime }}
            </p>
            <p class="frap">
              ( Block height {{idoinfoObj.sellEndHeight}})
            </p>
            <p class="frap">Distribution：</p>
            <p class="frap">{{ idoinfoObj.Distribution }}</p>
            <!-- <p class="frap">Base allocation: ${{idoinfoObj.baseAllocation}}</p> -->
          </div>
        </div>

        <!-- 锚点 -->
        <div class="anchor" style="width: 300px">
          <p class="Directory">Directory</p>
          <div
            class="box-on"
            v-if="idoinfoObj[item.key]"
            v-for="(item, index) in $t('cataloguelist')"
            :key="index"
            @click="anchor(item.key)"
          >
            <p>
              <span>{{ index + 1 }}.</span>
              {{ item.value }}
            </p>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- pouring -->
    <pouring
      class="time-st"
      v-if="TheWalletLinks_pouring"
      :boxtitle="true"
      :pouringdat="idoinfoObj"
    ></pouring>

    <!-- ido支付 -->
    <idodeal
      @clearidodeal="clearidodeal($event)"
      ref="idodealref"
      v-if="if_idodeal"
      :idoisWinnerList="idoisWinnerList"
      :reverseInitialTokenPrice="infosimpleObj"
    ></idodeal>
    <!-- success -->
    <successful
      @clearful="clearful"
      v-if="if_successful"
      :dataObj="objclearful"
    ></successful>

    <!-- 置顶 -->
    <img
      class="stick"
      v-if="if_stick"
      src="../../static/images/zhiding.png"
      alt=""
      srcset=""
      @click="BackToThetop()"
    />
  </div>

  <!-- 草稿，     即开始，    白名单，  公布抽奖结果，开始销售时间，销售完成，   领取 -->
  <!-- IDO项目状态 = ['Draft', 'Upcoming', 'WhiteList', 'Lottery',   'Selling',   'SellComplete', 'Extract'], -->
</template>
<script>
import pouring from "@/components/pouring";
import { ethers } from "ethers";
import idodeal from "./components/idodeal";
import successful from "./components/successful";
import { mapGetters } from "vuex";
// IDO项目详情 idoinfo  是否参与IDO项目idoisParticipated 用户中奖状态idoisWinner
import {
  idoinfo,
  infosimple,
  idoisParticipated,
  idoisWinner,
  canExtractAmount,
  chainNetHeight,
  getSocialWebsiteLogo,
} from "@/api/controller";

export default {
  components: {
    pouring,
    idodeal,
    successful,
  },
  data() {
    return {
      boxheight: false,
      blockhieght: 0,
      PutTheAsh: true,
      PutTheAsh1: true,
      preparingbox: true,
      LotteryBOX: false,
      IDOBox: false,
      DistributionBox: false,
      Amount: 0,
      progress: 0, //进行到的步骤
      IF_WhiteList: false, //白名单是否关闭
      IF_Lottery: 2, //是否中奖,
      IF_IDO: false, //IDO未选中,
      IF_Distribution: false, //未分配阶段
      if_idodeal: false,
      if_successful: false,
      EndBegins: false, // 是否显示 It's about to start, so stay tuned
      if_stick: false,
      idoinfoObj: {}, //IDO项目详情
      infosimpleObj: {}, // IDO项目简要信息,
      TheWalletLinks: false, // 只在未开始显示
      TheWalletLinks_pouring: false, //倒计时
      ConnectWallet: false, // 是否显示 Connect Wallet
      idoisWinnerList: {}, //查询中奖状态
      countDown: true,
      objclearful: {
        outputToken: 0,
        outputAmount: "",
      },
      Tabi: [
        {
          inputs: [
            {
              internalType: "contract IERC20",
              name: "currency_",
              type: "address",
            },
            {
              internalType: "contract IERC20",
              name: "asset_",
              type: "address",
            },
            { internalType: "uint256", name: "price_", type: "uint256" },
            { internalType: "uint256", name: "salesTotal_", type: "uint256" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "addr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "address",
              name: "customer",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "quota",
              type: "uint256",
            },
          ],
          name: "AddInvestorEvent",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "addr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "enum IDO.OrderState",
              name: "state",
              type: "uint8",
            },
          ],
          name: "ChangeStateEvent",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "addr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "contract IERC20",
              name: "_currency",
              type: "address",
            },
            {
              indexed: false,
              internalType: "contract IERC20",
              name: "_asset",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "_price",
              type: "uint256",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "salesTotal",
              type: "uint256",
            },
          ],
          name: "CreateIDOEvent",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "addr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "blockNumber",
              type: "uint256",
            },
          ],
          name: "RemoveExtractEvent",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "addr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "address",
              name: "userAddr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "assetAmount",
              type: "uint256",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "assetBalance",
              type: "uint256",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "currencyAmount",
              type: "uint256",
            },
          ],
          name: "SubscribeEvent",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "addr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "blockNumber",
              type: "uint256",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "createExtractEvent",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "addr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "address",
              name: "userAddr",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "userExtractEvent",
          type: "event",
        },
        {
          inputs: [],
          name: "_asset",
          outputs: [
            { internalType: "contract IERC20", name: "", type: "address" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "_currency",
          outputs: [
            { internalType: "contract IERC20", name: "", type: "address" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          name: "_extractAmount",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "", type: "address" }],
          name: "_investors",
          outputs: [
            { internalType: "uint256", name: "quota", type: "uint256" },
            { internalType: "uint256", name: "balance", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "_price",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "_salesTotal",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "_state",
          outputs: [
            { internalType: "enum IDO.OrderState", name: "", type: "uint8" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address[]", name: "addr_", type: "address[]" },
            { internalType: "uint256[]", name: "quota_", type: "uint256[]" },
          ],
          name: "addInvestor",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "addr", type: "address" }],
          name: "calcSubcribe",
          outputs: [
            {
              internalType: "uint256",
              name: "currencyAmount_",
              type: "uint256",
            },
            { internalType: "uint256", name: "assetAmount_", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "enum IDO.OrderState",
              name: "state_",
              type: "uint8",
            },
          ],
          name: "changeState",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address[]", name: "addr_", type: "address[]" },
          ],
          name: "checkInvestorValidity",
          outputs: [
            {
              internalType: "address[200]",
              name: "errAddr_",
              type: "address[200]",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "assetAmount_", type: "uint256" },
          ],
          name: "createExtract",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "blockNumber_", type: "uint256" },
            { internalType: "uint256", name: "assetAmount_", type: "uint256" },
          ],
          name: "createExtract",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "extract",
          outputs: [
            {
              internalType: "uint256",
              name: "extractAmount_",
              type: "uint256",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "addr_", type: "address" }],
          name: "getCanExtractAmount",
          outputs: [
            { internalType: "uint256", name: "notExtract_", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "addr_", type: "address" },
            { internalType: "uint256", name: "blockNumber", type: "uint256" },
          ],
          name: "getInvestorExtracted",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "addr_", type: "address" }],
          name: "getSubscribeBalance",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "blockNumber_", type: "uint256" },
          ],
          name: "removeExtract",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "payAmount_", type: "uint256" },
          ],
          name: "subscribe",
          outputs: [
            {
              internalType: "uint256",
              name: "payAssetAmount_",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "senderBalanceTotal_",
              type: "uint256",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      provider: {},
      linksLoading: false,
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  created() {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.requires();
    this.purse();
    // 接受header全局广播
    this.Event.$on("data-refresh", (res) => {
      // 钱包链接成功
      if (res) {
        // 用户断开钱包
        this.requires();
      } else {
        this.ConnectWallet = false;
        this.TheWalletLinks = true;
        this.requires();
      }
    });

    // 接受广播
    this.Event.$on("new-datals", (res) => {
      this.requires();
      this.purse();
    });

    // 接受axios。js通知
    this.Event.$on("schedule", (res) => {
      this.ConnectWallet = true; //显示登陆 Connect Wallet
      this.TheWalletLinks = false;
    });

    // header的通知
    this.Event.$on("search-project-details", (res) => {
      this.requires();
      this.purse();
    });
  },
  mounted() {
    //addEventlistener 第三个参数必须设置为true
    addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    addChain() {
      // 切换到领取网络
      var method = "wallet_addEthereumChain";
      let obkj = {
        chainId: "0x" + this.infosimpleObj.chainId.toString(16),
        chainName: this.infosimpleObj.network,
        rpcUrls: [],
        nativeCurrency: {},
        blockExplorerUrls: [],
      };
      this.$tool.FileList.forEach((element) => {
        if (element.chainId == this.infosimpleObj.chainId) {
          obkj.rpcUrls = element.rpcUrls;
          obkj.nativeCurrency = element.nativeCurrency;
          obkj.blockExplorerUrls = element.blockExplorerUrls;
        }
      });
      console.log(obkj, 3333);
      var params = [obkj];
      web3.currentProvider.sendAsync(
        {
          method,
          params,
        },
        (err, result) => {
          if (err) {
            this.$message({
              message: err.message,
              type: "warning",
            });
            // console.log('添加失败')
            return false;
          } else {
            this.Claim(); //开始领取
          }
        }
      );
    },

    async btn_logo(site) {
      let res = await getSocialWebsiteLogo(site);

      if (res.code === 0) {
        if (res.data.indexOf("http") > -1) {
          return res.data;
        }
      }
    },

    winopen(url) {
      window.open(url);
    },
    // 查询剩余领取
    async canExtractAmount() {
      let res = await canExtractAmount(this.$route.query.id);
      if (res.code === 0) {
        this.Amount = res.data;
      }
      // console.log(res,'查询剩余领取')
    },
    // 跳转到指定位置
    anchor(adrr) {
      document.querySelector(`#${adrr}`).scrollIntoView();
    },
    // 回看历史
    btn_whiteList(val) {
      let idoState = 0;
      if (this.idoinfoObj.idoState == "WhiteList") {
        idoState = 1;
      } else if (this.idoinfoObj.idoState == "Lottery") {
        idoState = 2;
      } else if (this.idoinfoObj.idoState == "Selling") {
        idoState = 3;
      } else if (
        this.idoinfoObj.idoState == "SellComplete" ||
        this.idoinfoObj.idoState == "Extract"
      ) {
        idoState = 4;
      } else {
        idoState = 0;
      }

      if (val <= idoState) {
        // 回看
        if (val == 2) {
          // 流程是否到了Lottery
          if (
            this.idoinfoObj.publishWinner == "Yes" &&
            new Date().getTime() >=
              new Date(this.idoinfoObj.lotteryDrawTime).getTime()
          ) {
            this.progress = 2;
          } else {
            return false;
          }
        }

        this.progress = val;

        if (this.progressStore == 2) {
          this.LotteryBOX = true;
          this.IDOBox = false;
          this.DistributionBox = false;
        } else if (this.progressStore == 3) {
          this.LotteryBOX = true;
          this.IDOBox = true;
          this.DistributionBox = false;
        } else if (this.progressStore == 4) {
          this.PutTheAsh = false;
          this.PutTheAsh1 = false;
          this.LotteryBOX = true;
          this.IDOBox = true;
          this.DistributionBox = true;
        }

        if (val == 1) {
          // 白名单已关闭不能查看白名单申请页
          if (
            new Date().getTime() >=
            new Date(this.idoinfoObj.whiteListEndTime).getTime()
          ) {
            this.IF_WhiteList = false;
          } else {
            this.IF_WhiteList = true;
          }
        } else if (val == 2) {
          this.idoisWinner();
        } else if (val == 3) {
          this.idoisWinner();
        } else if (val == 4) {
          // 开奖结果
          if (this.idoisWinnerList.winState == "Winner") {
            // 中过
            this.IF_Distribution = true;
          } else {
            this.IF_Distribution = false;
          }
        }
      }
      this.$forceUpdate();
    },

    Participation() {
      // 是否是到了ido状态，及以后
      if (
        this.idoinfoObj.idoState == "Selling" ||
        this.idoinfoObj.idoState == "SellComplete" ||
        this.idoinfoObj.idoState == "Extract"
      ) {
        // 判断是否中奖
        if (this.IF_Lottery == 1) {
          // 中奖
          this.IF_IDO = true;
          this.progress = 3;
        } else {
          this.IF_IDO = false;
          this.progress = 3;
        }
      }
    },
    detailPage(id) {
      this.$router.push({ path: "/application", query: { id } });
    },

    purseOn() {
      // 钱包链接  根目录index
      this.Event.$emit("landing");
    },

    // 查询中奖状态
    async idoisWinner() {
      this.linksLoading = true;
      let res = await idoisWinner(this.$route.query.id);
      // console.log(res,'查询中奖状态')
      try {
        if (res.code === 0) {
          this.idoisWinnerList = res.data;
          if (res.data.winState == "Winner") {
            this.IF_IDO = true;
            // 中奖
            if (res.data.inputAmount > 0) {
              // 当前有可支付的token数量

              if (
                new Date().getTime() >=
                new Date(this.idoinfoObj.sellEndTime).getTime()
              ) {
                // 销售时间已过
                this.PutTheAsh1 = false;
              } else {
                this.PutTheAsh1 = true;
              }
            } else {
              // 没有可支付的数量
              this.PutTheAsh1 = false;
            }
            // 中签 逻辑
            if (this.publishWinner) {
              this.IF_Lottery = 1;
            } else {
              this.IF_Lottery = 3; //即将开始，敬请期待
            }

            // 需要查询是否有领取份额
            this.IF_Distribution = true;
          } else {
            this.IF_Lottery = 2;
            this.IF_IDO = false;
            this.IF_Distribution = false;
          }
        }
      } catch (error) {
        console, log(error);
      }

      this.linksLoading = false;

    },

    // 检查是否链接钱包
    purse(data) {
      try {
        if (!sessionStorage.getItem("token")) {
          //   未连接钱包
          this.ConnectWallet = true;
          this.TheWalletLinks = false;
        } else {
          // 已连接
          this.ConnectWallet = false;
          this.TheWalletLinks = true;

          // 草稿，即开始，白名单，公布抽奖结果，开始销售时间，销售完成，领取
          // 显示进度
          if (data.idoState == "WhiteList") {
            this.progress = 1;
          } else {
            this.progress = 1;
          }
          if (
            new Date().getTime() >= new Date(data.lotteryDrawTime).getTime()
          ) {
            //当前时间要大于公布时间
            this.progress = 2;
            if (data.publishWinner == "Yes") {
              //导了中奖名单
              this.idoisWinner(); //查是否中奖
            } else {
              this.IF_Lottery = 3;
            }
          }
          if (data.idoState == "Selling" && data.publishWinner == "Yes") {
            //到了ido阶段，判断是否导入中奖者名单
            this.progress = 3;
            this.idoisWinner();
          }
          if (
            (data.idoState == "SellComplete" || data.idoState == "Extract") &&
            data.publishWinner == "Yes"
          ) {
            //到了最后阶段，判断是否导入中奖者名单
            this.progress = 4;
            this.idoisWinner();
            this.canExtractAmount(); //查剩余领取配额
          }

          // 保存当前位置，用于回看历史
          this.progressStore = this.progress;
        }
      } catch (error) {
        console.log(error);
      }
    },

    proportions(val, newval) {
      if ((val / newval) * 100 >= 0 && (val / newval) * 100 <= 100) {
        return (val / newval) * 100;
      } else {
        return 0;
      }
    },
    async chainNetHeight(chainId){
      let res = await chainNetHeight(chainId)
      this.blockhieght = res.data
    },
    async requires() {
      {
        let res = await idoinfo(this.$route.query.id);
        if (res.code === 0) {

          this.chainNetHeight(res.data.chainId)
          //  项目是否开始
          try {

            // ido开始时间
            res.data.whiteListStartTime
            // ido结束时间
            res.data.whiteListEndTime

            // 判断是否显示当前区块高度
            if(new Date().getTime() >= new Date(res.data.whiteListStartTime).getTime()){
              this.boxheight = true
            }else{
              this.boxheight = false
            }


            res.data.whiteListStartTime = res.data.whiteListStartTime.slice(
              0,
              16
            );
            res.data.whiteListEndTime = res.data.whiteListEndTime.slice(0, 16);
            res.data.lotteryDrawTime = res.data.lotteryDrawTime.slice(0, 16);
            res.data.sellEndTime = res.data.sellEndTime.slice(0, 16);
            res.data.sellStartTime = res.data.sellStartTime.slice(0, 16);
          } catch (error) {
            console.log(error);
          }

          if (
            new Date().getTime() >=
            new Date(res.data.whiteListStartTime).getTime()
          ) {
            // 已开始
            this.purse(res.data);
            this.TheWalletLinks_pouring = false;
          } else {
            // 未开始
            this.TheWalletLinks = true;
            this.TheWalletLinks_pouring = true;
            // 项目未开始不显示登陆
            this.ConnectWallet = false;
            this.EndBegins = true; //即将开始了
          }
          // 第一次领取时间
          if (res.data.extract.length > 0) {
            // 旧的
            // let str = res.data.extract[0].estimatedEffectiveTimestamp * 1000;
            // res.data["Distribution"] = this.$tool.timestampToTime(str);

            res.data["Distribution"] =
              res.data.extract[0].estimatedEffective.slice(0, 16);
          }

          // 解码
          // unescape()
          res.data.overview = unescape(res.data.overview);
          res.data.aboutLaunchpad = unescape(res.data.aboutLaunchpad);
          res.data.marketStrategy = unescape(res.data.marketStrategy);
          res.data.productViability = unescape(res.data.productViability);
          res.data.productRoadmap = unescape(res.data.productRoadmap);
          res.data.revenueStreams = unescape(res.data.revenueStreams);
          res.data.team = unescape(res.data.team);
          res.data.tokenEconomy = unescape(res.data.tokenEconomy);
          res.data.tokenMetricsDetailed = unescape(
            res.data.tokenMetricsDetailed
          );
          res.data.partners = unescape(res.data.partners);

          res.data.community.forEach(async (element) => {
            element["logo"] = await this.btn_logo(element.name);
            this.$forceUpdate();
          });

          // 到了时间，是否导入中奖名单
          if (res.data.publishWinner == "Yes") {
            this.publishWinner = true;
          } else {
            this.publishWinner = false;
          }
          // 销售开始时间
          if (
            new Date().getTime() >= new Date(res.data.sellStartTime).getTime()
          ) {
            this.preparingbox = false;
          } else {
            this.preparingbox = true;
          }

          // 处理第四阶段的内容
          if (res.data.extract.length > 0) {
            let arry = res.data.extract;
            arry.forEach((nkit) => {
              let time = this.$tool.timestampToTime(
                nkit.estimatedEffectiveTimestamp * 1000
              );
              nkit["time"] = this.$tool.conversion(time, 6);
              nkit["percent"] =
                (nkit.amount / res.data.fundraiseGoal) * 100 + "%"
                  ? (nkit.amount / res.data.fundraiseGoal) * 100
                  : 0;

              if (new Date().getTime() >= new Date(time)) {
                // Completed
                nkit["condition"] = "Completed";
              } else {
                // Upcoming
                nkit["condition"] = "Upcoming";
              }
            });

            // console.log(arry, '处理后的参数')
          }

          res.data.whiteListStartTime = res.data.whiteListStartTime.replace(
            new RegExp("-", "g"),
            "/"
          );
          res.data.whiteListEndTime = res.data.whiteListEndTime.replace(
            new RegExp("-", "g"),
            "/"
          );
          res.data.lotteryDrawTime = res.data.lotteryDrawTime.replace(
            new RegExp("-", "g"),
            "/"
          );
          res.data.sellStartTime = res.data.sellStartTime.replace(
            new RegExp("-", "g"),
            "/"
          );
          res.data.sellEndTime = res.data.sellEndTime.replace(
            new RegExp("-", "g"),
            "/"
          );
          res.data.Distribution = res.data.Distribution.replace(
            new RegExp("-", "g"),
            "/"
          );

          this.idoinfoObj = res.data;
        }
      }
      {
        let res = await infosimple(this.$route.query.id);
        // console.log(res,'IDO项目简要信息')
        if (res.code === 0) {
          this.infosimpleObj = res.data;

          if (
            new Date().getTime() >=
            new Date(res.data.whiteListStartTime).getTime()
          ) {
            this.countDown = false;
          } else {
            this.countDown = true;
          }
        }
      }
      {
        let res = await idoisParticipated(this.$route.query.id);
        // console.log(res, '用户是否参已报名IDO')
        if (res.code === 0) {
          // 是否显示 Apply for whitelist now
          if (res.data) {
            // 申请过白名单
            if (
              new Date().getTime() >=
              new Date(this.idoinfoObj.whiteListEndTime).getTime()
            ) {
              this.IF_WhiteList = false;
            } else {
              this.IF_WhiteList = true;
            }
          } else {
            // 没申请过
            //是否已经过了白名单结束时间
            if (
              new Date().getTime() >=
              new Date(this.idoinfoObj.whiteListEndTime).getTime()
            ) {
              this.IF_WhiteList = false;
            } else {
              this.IF_WhiteList = true;
            }
          }
        }
      }
    },

    handleScroll() {
      let scrollTop = document.querySelector(".el-main").scrollTop;
      if (scrollTop < 100) {
        this.if_stick = false;
      } else {
        this.if_stick = true;
      }
    },

    BackToThetop() {
      // 回到顶部
      let scrollTop = document.querySelector(".el-main").scrollTop;
      let setint = setInterval(() => {
        if (scrollTop <= 1) {
          // console.log('结束')
          clearInterval(setint);
        } else {
          document.querySelector(".el-main").scrollTop = scrollTop -= 80;
        }
      }, 10);
    },

    async IDO(numbers, decimals) {
      // console.log('支付的参数', numbers, decimals)
      let transactionParameters;
      // new ethers.utils.BigNumber
      // numbers 交易数量
      // decimals token精度
      // methodName 调什么方法在 Tabi 找
      // encode 传几个参数在 Tabi 里面inputs数组有几个传几个，类型看internalType，值看internalType值
      // const numberOfTokens = ethers.utils.parseUnits(numbers.toString(), decimals);
      const iface = new ethers.utils.Interface(this.Tabi);
      const data = iface.functions.extract.encode([]);
      transactionParameters = {
        to: this.idoinfoObj.idoContractAddress,
        from: this.idoinfoObj.address, //验证合约调用需要from,必传
        value: "0x00",
        data: data,
      };
      //    console.log(transactionParameters)
      const failed = await this.validate(transactionParameters);
      if (failed) {
        console.error("failed approveERC20" + failed);
        return { success: false, msg: "failed crossIn" + failed };
      }
      delete transactionParameters.from; //etherjs 4.0 from参数无效 报错
      return this.sendTransaction(transactionParameters);
    },
    async sendTransaction(tx) {
      const wallet = this.provider.getSigner();
      return await wallet.sendTransaction(tx);
    },
    //验证交易参数
    async validate(tx) {
      try {
        const result = await this.provider.call(tx);
        return ethers.utils.toUtf8String("0x" + result.substr(138));
      } catch (e) {
        return false;
      }
    },

    async Claim() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.loading = true;
      let res;
      try {
        res = await this.IDO();
      } catch (error) {
        loading.close();
        this.$message.error(error.data.message);
      }
      let result = await res.wait();
      // console.log(result,'领取....')
      if (result.status == 1) {
        // 领取成功
        this.$message({
          message:
            this.$i18n.locale == "en" ? "Received successfully" : "领取成功",
          type: "success",
        });
        loading.close();
        this.requires();
      } else {
        // 领取成功
        loading.close();
        this.$message({
          message: this.$i18n.locale == "en" ? "Failed to claim" : "领取失败",
          type: "success",
        });
      }
    },

    clearful() {
      this.requires();
      this.purse();
      this.if_successful = false;
    },

    clearidodeal(val) {
      if (val) {
        // 打开支付成功界面
        this.objclearful.outputToken = this.infosimpleObj.outputToken; //用户得到的币类
        this.objclearful.outputAmount =
          val * this.infosimpleObj.reverseInitialTokenPrice; //用户将得到多少数量
        this.if_successful = true;
      }
      this.if_idodeal = false;
    },
  },
};
</script>

<style lang="less" scoped>
.a-new-details {
  position: relative;
  .stick {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 5%;
    right: 3%;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
      background: #22dda7;
    }
  }
  .details-backgroun {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    height: 380px;
    background-size: cover;
    background-position: right;
  }
  .time-st {
    position: absolute;
    top: 214px;
    left: 40px;
  }
  /deep/.cont {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 80%;
      height: auto;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 30px;
      margin-right: 20px;
      padding: 25px;
    }
    .right {
      width: 300px;
    }
    .essay {
      .essay-title {
        font-size: 16px;
        font-weight: bold;
        color: #fefefe;
        margin-bottom: 10px;
        padding-top: 10px;
      }
      .content {
        line-height: 20px;
        font-size: 14px;
        color: #72767e;
        margin-bottom: 15px;
        word-break: break-all;
        li {
          list-style-position: inside;
        }
        p {
          iframe {
            width: 100% !important;
            height: 415px !important;
          }
        }
      }
    }
    .anchor {
      position: sticky;
      top: 20px;
      margin-top: 28px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 30px;
      padding: 25px 20px 10px;
      box-sizing: border-box;
      .Directory {
        font-size: 16px;
        font-weight: 550;
        margin-bottom: 17px;
      }
      .box-on {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        p {
          font-size: 14px;
        }
        .el-icon-arrow-right {
          font-size: 16px;
        }

        &:hover {
          color: #fefefe;
        }
      }
    }
    .BitHotel {
      .el-progress {
        margin-top: 7px;
        margin-bottom: 12px;
        .el-progress-bar__outer {
          background: rgba(#1edfa6, 0.1);
        }
      }
      padding: 20px 0 30px;
      box-sizing: border-box;
      .title-tel {
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        margin-top: 20px;
      }
      .exchange {
        font-size: 14px;
        color: #22dda7;
        line-height: 18px;
      }
      .proportion {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .frap {
        font-size: 14px;
        line-height: 2;
        word-break: break-all;
      }
      .sharing {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 40px;

        .svg-icon {
          width: 22px;
          height: 22px;
          margin: 0 10px;
          cursor: pointer;
          &:hover {
            color: #fefefe;
          }
        }
      }
      .Connect-Wallet {
        cursor: pointer;
        width: 236px;
        height: 38px;
        background: rgba(#1edfa6);
        border-radius: 10px;
        font-size: 16px;
        color: #22252c;
        text-align: center;
        line-height: 38px;
        margin: auto;
        margin-top: 27px;
      }
      .title-type {
        font-size: 12px;
        margin-top: 10px;
        text-align: center;
      }
      .logo-img {
        display: block;
        margin: 0 20px;
        box-sizing: border-box;
        width: 236px;
        height: 180px;
        border-radius: 30px;
      }
      width: 100%;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 30px;
    }
  }
  .blockght{
    border-radius: 10px;
    height: 40px;
    padding: 0 20px;
    font-size: 16px;
    background: #ffffff;
    color: #333333;
    line-height: 40px;
    width: 206px;
    position: absolute;
    z-index: 999;
    top: 40px;
    left: 620px;
  }
  /deep/.schedule {
    position: absolute;
    z-index: 999;
    top: 40px;
    width: 600px;
    height: 149px;
    background: rgba(#2d3138, 0.52);
    border-radius: 10px;
    .el-loading-mask {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .contianer {
      height: 100px;

      .WhiteList {
        width: 206px;
        height: 39px;
        background: #1edfa6;
        border-radius: 10px;
        font-size: 14px;
        color: #fefefe;
        text-align: center;
        line-height: 39px;
      }
      .Lottery {
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
      }
      .Distribution {
        display: flex;
        align-items: center;
        .Claim {
          padding: 0 20px;
          box-sizing: border-box;
          cursor: pointer;
          margin-left: 20px;
          width: 94px;
          height: 33px;
          background: #1edfa6;
          border-radius: 10px;
          font-size: 12px;
          font-weight: bold;
          color: #22252c;
          text-align: center;
          line-height: 33px;
        }
      }
      .Confirm-Participation,
      .Purchase {
        font-size: 14px;
        color: #22252c;
        width: 226px;
        cursor: pointer;
        height: 33px;
        background: #1ecc9e;
        border-radius: 10px;
        text-align: center;
        line-height: 33px;
        margin-top: 16px;
      }
    }

    .top {
      border-radius: 10px;
      width: 600px;
      height: 49px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 0 18px;
      box-sizing: border-box;
      .el-icon-arrow-right {
        font-size: 16px;
      }
      .path {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        .svg-icon {
          font-size: 22px;
          margin-right: 7px;
        }
      }
      .path-path {
        color: #fff;

        .svg-icon {
          color: #1edfa6;
        }
      }
    }
  }
  .not-login {
    display: flex;
    align-items: center;
    justify-content: center;

    &-btn {
      padding: 0px 30px;
      background: #1edfa6;
      border-radius: 10px;
      font-size: 16px;
      color: #22252c;
      text-align: center;
      line-height: 38px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
.isMobile {
  .cont {
    flex-wrap: wrap;
  }
  .cont .essay .content {
    word-break: break-all;
  }
  .cont .left {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .cont .right {
    width: 100%;
  }
  .cont .BitHotel .logo-img {
    margin: 0 auto;
  }

  .cont .anchor {
    width: 100% !important;
    margin-bottom: 60px;
  }

  .schedule {
    width: 100%;
  }
  .schedule .top {
    width: 100%;
    flex-wrap: wrap;

    line-height: 2;
  }
}
</style>
