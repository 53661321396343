<template>
  <div class="successful">
    <el-dialog :visible.sync="dialogTableVisible" :before-close="clearsuccessful" :destroy-on-close="true">
        <img class="succ" src="../../../static/images/makeanew/succells.png" alt="">
        <p class="Successful">Purchase Successful</p>
        <p class="Successful" style="font-size: 14px;color: #9F9F9F;margin-top:15px;">{{datum.outputAmount}} {{datum.outputToken}} will be credited to your wallet</p>
        <div class="complete cur" @click="clearsuccessful">complete</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    props:['dataObj'],
    data(){
        return{
            dialogTableVisible: true,
            datum:{}
        }
    },
    watch:{
        dataObj:{
            handler(newval){
                this.datum = newval
            },
            immediate: true
        }
    },
    methods:{
        clearsuccessful(done){
            this.$emit('clearful')
        },
    }
}
</script>

<style lang="less" scoped>
.successful{
    /deep/.el-dialog__wrapper{
        .el-dialog{
            width: 385px;
            height: 413px;
            background: #FFFFFF;
            border-radius: 30px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto !important;
            box-sizing: border-box;
            .el-dialog__body{
                padding-top: 0;
                .Successful{
                    text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                    color: #1EDFA6;
                }
                .complete{
                    width: 308px;
                    height: 41px;
                    background: #1EDFA6;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 41px;
                    margin: auto;
                    margin-top: 24px;
                    margin-bottom: 26px;
                }
                .succ{
                    width: 188px;
                    height: 160px;
                    display: block;
                    margin: auto;
                    margin-bottom: 50px;
                }
            }
            .el-dialog__header{
                .el-dialog__headerbtn .el-dialog__close{
                    color: #fff;
                    font-size: 12px;
                }
                .el-icon-close:before{
                    border: 1px solid #fff;
                    border-radius: 50%;
                    padding: 2px;
                }
                .el-dialog__title{
                    font-size: 14px;
                    color: #fff;
                }
            }
        }

    }

}
</style>
