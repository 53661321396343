<template>
  <div class="banner-left">
    <p
      v-if="boxtitle"
      style="
        width: 256px;
        font-size: 12px;
        font-weight: bold;
        color: #8a97c1;
        margin-bottom: 15px;
      "
    >
      Start countdown:
    </p>
    <div class="timer">
      <div>
        <p>{{ btnDay(day, 1) }}</p>
        <p style="margin-left: 3px">{{ btnDay(day, 2) }}</p>
      </div>
      <div>
        <p>{{ btnDay(hour, 1) }}</p>
        <p style="margin-left: 3px">{{ btnDay(hour, 2) }}</p>
      </div>
      <div>
        <p>{{ btnDay(min, 1) }}</p>
        <p style="margin-left: 3px">{{ btnDay(min, 2) }}</p>
      </div>
      <div>
        <p>{{ btnDay(second, 1) }}</p>
        <p style="margin-left: 3px">{{ btnDay(second, 2) }}</p>
      </div>
    </div>
    <!-- <div class="data">
      <p style="text-align: center">Days</p>
      <p style="text-align: center">Hours</p>
      <p style="position: relative; left: -4px">Minutes</p>
      <p style="position: relative; left: -6px">Seconds</p>
    </div> -->
    <!-- <p class="Ends-in">Ends in</p> -->
  </div>
</template>

<script>
export default {
  props: {
    boxtitle: {
      type: Boolean,
      default: false,
    },
    pouringdat: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      day: "00",
      hour: "00",
      min: "00",
      second: "00",
      curStartTime: "",
    };
  },
  watch: {
    pouringdat: {
      handler(newvl) {
        if (newvl) {
          if (window.location.hash.indexOf("launchpad") > -1) {
            this.curStartTime = newvl.sellEndTime;
          } else {
            this.curStartTime = newvl.whiteListStartTime;
          }
          this.countTime();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    btnDay(day, index) {
      if (index == 1) {
        if (day > 9) {
          return day.toString().substring(0, 1);
        } else {
          return 0;
        }
      } else {
        if (day > 9) {
          return day.toString().substring(1, 2);
        } else {
          if (day.toString().length > 1) {
            return day.toString().substring(1, 2);
          } else {
            return day;
          }
        }
      }
    },
    // 倒计时
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        let day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        this.day = day < 10 ? "0" + day : day;
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
      } else {
        this.day = "00";
        this.hour = "00";
        this.min = "00";
        this.second = "00";
      }
      this.$forceUpdate();
      // console.log(this.day + '天' + this.hour + '小时' + this.min + '分钟' + this.second + '秒')
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner-left {
  width: 256px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 32px;
  left: 40px;
  .Ends-in {
    width: 256px;
    font-size: 14px;
    color: #1edfa6;
    margin-top: 10px;
    text-align: right;
  }
  .data {
    width: 256px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
    p {
      width: 44px;
      font-size: 14px;
      color: #1edfa6;
    }
  }
  .timer {
    width: 256px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
    }
    div p {
      border-radius: 3px;
      width: 20px;
      height: 33px;
      // background: url("../static/images/laubcgpad/dd2f0c1c5cbf02e4c33d8a9bf63be69.png")
      //   100% center/100% no-repeat;
      background: rgba(48, 47, 54, 0.6);
      font-size: 18px;
      font-weight: bold;
      color: #1edfa6;
      text-align: center;
      line-height: 33px;
    }
  }
}
</style>
